import type { WidgetConfigTypeDefs } from '@gql/schema';
import { getBreakpointFromWidth, MediaType } from '../assets/scss/variables';

const DEFAULT_PAGE_SIZE = 10;

export type GlobalStore = {
  modalState: 'open' | 'close';
  pageSize: number;
  listType: 'list' | 'gallery';
  bookmarksCount: number;
  containerWidth: number;
  mediaType: MediaType;
};

export const useGlobalStore = defineStore('globalStore', () => {
  const state = reactive<GlobalStore>({
    modalState: 'close',
    pageSize: DEFAULT_PAGE_SIZE,
    listType: 'list',
    bookmarksCount: 0,
    containerWidth: 0,
    mediaType: MediaType.MD,
  });

  const bookmarks = useLocalStorage<string[]>('bookmarks', []);

  watchEffect(() => {
    state.bookmarksCount = bookmarks.value.length;
    state.mediaType = getBreakpointFromWidth(state.containerWidth);
  });

  const addBookmark = (bookmark: string) => {
    if (!bookmarks.value.includes(bookmark)) {
      bookmarks.value.push(bookmark);
    }
  };

  const removeBookmark = (bookmark: string) => {
    bookmarks.value = bookmarks.value.filter((b) => b !== bookmark);
  };

  const setModalState = (newState: 'open' | 'close'): void => {
    state.modalState = newState;
  };

  const setContainerWidth = (width: number): void => {
    state.containerWidth = width;
  };

  const toggleModalState = (): void => {
    state.modalState = state.modalState === 'open' ? 'close' : 'open';
  };

  const increasePageSize = (size: number): void => {
    if (!Number.isInteger(size) || size <= 0) {
      console.error('Size must be a positive integer');
      return;
    }
    state.pageSize += size;
  };

  const resetPageSize = (): void => {
    state.pageSize = DEFAULT_PAGE_SIZE;
  };

  const setListType = (
    widgetTypeConfig: WidgetConfigTypeDefs | null = null,
    type?: GlobalStore['listType']
  ): void => {
    if (!type && !widgetTypeConfig) {
      return;
    }

    if (widgetTypeConfig && isEmpty(state.listType)) {
      state.listType = widgetTypeConfig.entryViewType?.name as
        | 'list'
        | 'gallery';
    } else if (!isEmpty(type)) {
      state.listType = type!;
    }
  };

  const toggleListType = (): void => {
    state.listType = state.listType === 'list' ? 'gallery' : 'list';
  };

  const readBookmarksStateFromRoute = (): void => {
    const { query } = useRoute();

    const bookmarksQueryParam = query.bookmarks;

    if (Array.isArray(bookmarksQueryParam)) {
      bookmarks.value = bookmarksQueryParam
        .filter((bookmark): bookmark is string => bookmark !== null)
        .map((bookmark) => bookmark.trim());
    } else if (bookmarksQueryParam) {
      bookmarks.value = bookmarksQueryParam
        .split(',')
        .map((bookmark: string) => bookmark.trim());
    }
  };

  return {
    // (reactive) state itself
    state,

    // actions
    setModalState,
    setContainerWidth,
    toggleModalState,
    increasePageSize,
    resetPageSize,
    setListType,
    toggleListType,
    addBookmark,
    removeBookmark,
    readBookmarksStateFromRoute,
  };
});
