import { MAPTOOLKIT_APIKEY } from '../constants.config';
import type { BaseCoords } from '@models/BaseCoords';

export default async (coordinates: BaseCoords): Promise<string> => {
  const response = await fetch(
    `https://geocoder.maptoolkit.net/reverse?lat=${coordinates.latitude}&lon=${coordinates.longitude}&api_key=` +
      MAPTOOLKIT_APIKEY
  );

  if (!response.ok) {
    throw new Error(
      `MapToolkit geocoder api call failed: ${response.statusText}`
    );
  }

  const data = await response.json();
  return data.display_name ?? '';
};
