import type { Nullable } from '@models/CustomUtilityTypes';

/**
 * Checks if a data value is empty.
 *
 * A value is considered empty if it is:
 * - `null` or `undefined`
 * - An empty string (after trimming whitespace)
 * - An empty object (no own properties)
 * - An empty array
 * - An array where every item is empty
 *
 * @param data - The value or an array of values to check for emptiness. It can be of any type.
 * @returns `true` if the value is empty, `false` otherwise.
 */
export default function isEmpty<T>(
  data: MaybeRefOrGetter<Nullable<T> | Nullable<T>[] | T | T[]>,
  checkFor: 'some' | 'every' = 'every'
): boolean {
  return rawIsEmpty(toValue(data), checkFor);
}
